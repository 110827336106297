<template>
    <div class="media">
<iframe width="900" height="440" src="https://www.youtube.com/embed/LjeJmHg_S4A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
</template>

<script>

export default {
    components:{}
}
</script>

<style>

.media{  
  margin: 0 auto;
  padding: 29px;
  background-color: rgb(240, 228, 228);
  width: 1024px;
  min-height: 450px;
  border: 1px solid black;
  border-top: 0 0 0;
}
</style>