<template>
<Navigation />
</template>

<script>
import Navigation from './components/navigation/Navigation.vue'

export default {
  name: 'App',
  components: {
    Navigation
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}

body {
  background: linear-gradient(to bottom, #555, #999);
  background-attachment: fixed;
}



</style>
