<template>
  <!-- <header id="navigation">
<ul>
  <li class="logo">Logo</li>
  <li class="nav-item"> <router-link to="/">Home</router-link></li>
  <li class="nav-item"><router-link to="/services">Services</router-link></li>
  <li class="nav-item" ><router-link to="/media">Media</router-link></li>
  <li class="nav-item"><router-link to="/about">About</router-link></li>
</ul>
    </header> -->
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">Logo</a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">

          <li class="nav-item active">
          <router-link to="/"><a class="nav-link">Home </a></router-link>
          </li>

        <li class="nav-item">
          <a class="nav-link" href="#">Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Media</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Contact</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Certifications</a>
        </li>
        <li class="nav-item">
          <router-link to="/about"><a class="nav-link">About </a></router-link>
          </li>

        <!-- <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li> -->
        <!-- <li class="nav-item">
        <a class="nav-link disabled" href="#">Disabled</a>
      </li> -->
      </ul>
    </div>
  </nav>
  <router-view />
</template>

<script>
// import Home from '../Home.vue'
// import About from '../About.vue'
export default {
  components: {},
};
</script>

<style scoped>
</style>