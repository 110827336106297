<template>
  <br/>
  <div class="about">
<h5>LOCATION COORDINATES</h5>
<div>longitude: {{long}}</div>
<div>latitude: {{lat}}</div>
<div class="bold-text">Current Street Address: </div>
<div >{{addressData}}</div>
<div class="bold-text">Current Address ID: </div>
<div>{{addressId}}</div>
<br />
  <div>{{addresslistStorage}}</div>
  <br />
  <div id="visited">
    <h5>VISITED ADRESS LIST:</h5>
    <hr>
  <div v-for="(items,index) in visitedAddress" :key="index">
    <ul>
      <li class="bold-text">Address # {{index + 1}}</li>
      <li>{{items.formatted_address}}</li>
      <li>{{items.address_id}}</li>
      <hr>
  </ul></div>
  </div>

  </div>
</template>

<script setup>
import {onMounted} from "vue"
import axios from 'axios';
 
const {ref} = require("vue")

const addressData = ref([]);
let addressId = ref(null);

let long = ref(null);
let lat = ref(null);
//let getAddressList = ref([]);
let visitedAddress = JSON.parse(localStorage.getItem('addressList')) || [];

const addressList = ref({
  formatted_address: null,
  address_id: null
});


const errorHandler =(err) => {
            if(err.code == 1) {
               alert("Error: Access is denied!");
            } else if( err.code == 2) {
               alert("Error: Position is unavailable!");
            }
         }

  const options = {
  enableHighAccuracy: true,
  maximumAge: 30000,
  timeout: 27000
};



onMounted(async () => {
navigator.geolocation.getCurrentPosition(async (position)=>{
lat.value = position.coords.latitude;
long.value = position.coords.longitude;

await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat.value},${long.value}&key=AIzaSyCAZX5kIzl7m4Q0Ir3IzJV8PxhETfF8A4w`)
    .then(response => {
      console.log(response.data)
      addressData.value = response.data.results[0].formatted_address;
      addressId.value = response.data.results[0].place_id;

      addressList.value.formatted_address = response.data.results[0].formatted_address;
      addressList.value.address_id = response.data.results[0].place_id;
    
      visitedAddress.push(addressList.value);
      window.localStorage.setItem("addressList", JSON.stringify(visitedAddress));
      //getAddressList.value = window.localStorage.getItem('addressList');

      console.log(visitedAddress)


    })
}, errorHandler, options);



})
</script>

<style scoped>
div,ul{
  list-style: none;
  color: black;
  background: white;
}
#visited {
border-top: 1px dashed gray;
padding-top: 10px;
}

.bold-text {
  font-weight: 600;
}

</style>


