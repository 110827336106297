import { createWebHistory, createRouter } from "vue-router";
import Home from "../components/Home.vue";
import About from "../components/About.vue";
import Media from "../components/Media.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/media",
    name: "Media",
    component: Media,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;