<template>
  <div class="wrapper">
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel"
      data-interval="6000"
    >
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            class="d-block w-100"
            src="../assets/slide-1.svg"
            alt="First slide"
          />
          <div class="carousel-caption d-none d-md-block">
            <h5>Workout at home!</h5>
            <p>Train at the conviance of your home.</p>
          </div>
        </div>
        <div class="carousel-item">
          <img
            class="d-block w-100"
            src="../assets/slide-1.svg"
            alt="Second slide"
          />
          <div class="carousel-caption d-none d-md-block">
            <h5>Portable Ring</h5>
            <p>I bring the equipment you provide the time and space.</p>
          </div>
        </div>
        <div class="carousel-item">
          <img
            class="d-block w-100"
            src="../assets/slide-1.svg"
            alt="Third slide"
          />
          <div class="carousel-caption d-none d-md-block">
            <h5>Simulate boxing training!</h5>
            <p>Time to train like a champ!</p>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <div class="email-form">
      <form>
        <div class="alert alert-dark" role="alert">Check Availability</div>
        <div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label"
              >Email address</label
            >
            <input
              type="email"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="name@example.com"
            />
          </div>
          <div class="mb-3">
            <button type="button" class="btn btn-dark">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const heroText = ref('Boxing To-Go');

    // expose to template
    return {
      heroText,
    };
  },
};
</script>

<style scoped>
.hero {
  min-height: 100px;
  background-color: rgb(27, 29, 29);
  color: white;
  padding: 20px 0 20px 0;
  font-size: 2.6em;
  font-family: fantasy;
  letter-spacing: 14px;
}

/* .carousel-item {
  height: 300px;
} */

.carousel {
  width: 100%;
}

/* .item img {
    position: absolute;
    top: 0;
    left: 0;
} */

.wrapper {
  display: flex;
  justify-content: center;
}

.email-form {
  display: flex;
  align-items: center;
  padding: 25px;
  background-color: #646262;
  color: white;
  text-align: left;
  width: 350px;
}

@media (max-width: 600px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .email-form {
    width: 100%;
    margin-top: 30px;
  }

  .carousel-item {
    height: 100%;
  }

  form {
    width: 100%;
  }
}
</style>